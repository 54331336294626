<template>
  <b-col xl="4" lg="6" md="6" sm="12" @click="$emit('onClickCard', id)" class="cursor-pointer mb-3">
    <div class="card card-custom card-stretch meeting-box">
      <!--begin::Body-->
      <div class="card-body px-1 pt-0 pb-1 meeting-box-body">
        <!--begin::Toolbar-->
        <div class="d-flex justify-content-end">
          <div class="dropdown dropdown-inline" data-toggle="tooltip" title="Quick actions" data-placement="left" v-if="actions">
            <a href="#" class="btn btn-clean btn-hover-light-primary btn-sm btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="ki ki-bold-more-hor"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-md dropdown-menu-right">
              <!--begin::Navigation-->
              <ul class="navi navi-hover">
                <li class="navi-header font-weight-bold py-4">
                  <span class="font-size-lg">Choose Label:</span>
                  <i class="flaticon2-information icon-md text-muted" data-toggle="tooltip" data-placement="right" title="Click to learn more..."></i>
                </li>
              </ul>
              <!--end::Navigation-->
            </div>
          </div>
        </div>
        <!--end::Toolbar-->
        <!--begin::User-->
        <div class="mb-0" >
          <!--begin::Title-->
          <div class="d-flex flex-column">
            <span class="text-muted font-weight-bold"></span>
          </div>
          <!--end::Title-->

          <div class="d-flex justify-content-between align-items-center mr-2">
            <span class="primary-gray font-weight-bold font-size-h4 mb-0">
              <div class="symbol symbol-circle symbol-lg-75 mr-3 font-size-h3-lg">
                <span
                    v-html="getIconByKey('icons.meeting.pop-up-meeting', {
                class: 'w-50px h-50px object-cover d-inline-block'
              })"></span>
                <span class="overflow-hidden">{{ name }}</span>
              </div>
            </span>
            <a href="#" class="primary-gray d-flex items-center" style="align-items: center;">
              <span
                  v-html="getIconByKey('icons.meeting.calender', {
                    class: 'w-25px h-25px d-inline-block mr-3',
                    style: 'opacity: 0.4;'
                  })"></span>
              {{ createdAt }}</a>
          </div>
        </div>
        <!--end::User-->
        <!--begin::Info-->
        <div class="mb-0 ml-2 mr-2" style="margin-top: -17px;">
          <div class="d-flex justify-content-between align-items-cente my-1">
            <span class="text-primary font-weight-bold mr-2 mt-3">{{ $t('general.meeting_created_by')}}: {{ createdBy }} </span>
            <a href="#" class="primary-gray text-uppercase font-weight-bold mt-3">{{ companyName }}</a>
          </div>
        </div>
        <!--end::Info-->
      </div>
      <!--end::Body-->
    </div>
  </b-col>
</template>

<script>
export default {
  name: "MeetingListItem",
  props: {
    id: {
      required: true,
    },
    name: {
      default: "No Name"
    },
    createdAt: {
      required: true,
    },
    createdBy: {
      required: true,
    },
    companyName: {
      required: true,
    },
    actions: {
      required: false
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/components/_variables.demo.scss';
$primary-gray: #81818d;
.font-3xl {
  font-size: 1.4rem !important;
}
.meeting-box {
  border-radius: 20px !important;
  .meeting-box-body{
    border-radius: 20px !important;
    border: 1px solid $primary-gray;
    box-shadow: 0.5px 1px $primary-gray-shadow;
  }
  .primary-gray {
    color: $primary-gray;
  }
  a.text-hover-primary:hover, .text-hover-primary:hover {
    color: #1cac79 !important;
  }
}
</style>